<template>
  <div class="xinwen" id="xinwen">
    <headerNav></headerNav>
    <el-carousel :interval="5000" arrow="always" height="750px">
      <el-carousel-item v-for="(item,index) in bannerList" :key="index">
        <img :src="item.img" alt="" />
      </el-carousel-item>
    </el-carousel>
    <div class="bigBox">
      <div class="titleBj">
        <div class="titleWz">课程介绍</div>
        <p class="pTitle">多彩课程，引领未来</p>
        <p class="pInfo">
          在燕京雍阳，我们为孩子们提供多彩而富有启发性的课程，旨在激发他们的学习热情，培养全面素养，为未来的学习之路打下坚实基础。
        </p>
      </div>

      <div class="jsBig">
        <div class="lefrBig">
          <p class="bigText">
            燕京雍阳自豪地采用国际文凭（IB）理念，践行国际化教育的标准，培养具备全球胜任力的公民。这一理念注重跨学科的学习，培养批判性思维、创造性问题解决能力以及社会责任感。通过IB，鼓励孩子们成为积极思考的全球公民。
          </p>
        </div>
        <div class="rightBig">
          <img
            src="https://lwjy.oss-cn-beijing.aliyuncs.com/school/yuan.png"
            class="bigImg"
          />
        </div>
      </div>
      <div class="jsH">
        <p class="HTitle">一、STEM探索</p>
        <p class="HText">
          燕京雍阳坚持探究式学习，为孩子们提供STEM（科学、技术、工程和数学）课程。通过实验和项目，他们将探索科学的奥秘，培养问题解决能力，提高逻辑思维和创新意识。
        </p>
      </div>
      <div class="jsL">
        <p class="LTitle">二、语言艺术</p>
        <p class="LText">
          燕京雍阳注重孩子的语言发展，提供英文启蒙课程，采用欧洲语言共同参考框架标准，帮助孩子掌握英语基本技能。此外，鼓励孩子积极参与自主阅读和亲子阅读，培养他们的阅读习惯和英文表达能力。
        </p>
      </div>
      <div class="jsH">
        <p class="HTitle">三、艺术与创意</p>
        <p class="HText">
          燕京雍阳鼓励孩子们发掘自己的艺术潜力。我们提供美术和音乐课程，让孩子们通过创作表达自己的想法和情感。艺术教育有助于培养他们的创造力、想象力和审美观。
        </p>
      </div>
      <div class="jsL">
        <p class="LTitle">四、活动与体育</p>
        <p class="LText">
          燕京雍阳认为身体健康与学习成绩密不可分，因此提供多种体育和户外活动，如高尔夫、国际象棋等。这不仅有助于培养孩子的身体素质，还锻炼了他们的团队合作和竞技精神。
        </p>
      </div>
      <div class="jsH">
        <p class="HTitle">五、自然拼读</p>
        <p class="HText">
          为了帮助孩子系统地学习英语语言，燕京雍阳采用自然拼读法。通过这个方法，孩子们能够更轻松地学习发音规则和拼读技巧，扩充英语词汇，为英文阅读打下坚实基础。
        </p>
      </div>
      <!-- 特色课程 -->
      <div class="tsTitleBj teseKcPcShow">
        <div class="tsTitleWz">特色课程</div>
      </div>
      <!-- PC端 -->
      <div class="teseKcPcShow">
        <div class="tsBj">
          <div class="tsOne">
            <div class="tsLeft">
              <img
                src="https://lwjy.oss-cn-beijing.aliyuncs.com/school/meishuchuangzuoOne.png"
                class="tsImg"
                style="margin-top: 120px"
              />
            </div>
            <div class="tsRight">
              <p class="tsTitle">美术创作</p>
              <p class="tsText">
                在燕京雍阳，鼓励孩子们从3岁左右就开始接触美术。美术课程有助于提高孩子的手脑协调能力、创造力和观察力。通过涂鸦、涂色和粘贴等方式，孩子们可以自由表达情感和想法，培养他们的艺术潜能。在4岁左右，孩子们的绘画技能将更加成熟，他们可以开始使用画笔，逐渐培养自己的绘画风格和技巧。在5-7岁的阶段，美术活动将有助于培养孩子的观察力、想象力和创造力，同时提高他们的合作和团队合作能力。
              </p>
            </div>
          </div>
          <div class="tsTwo">
            <div class="tsRight">
              <p class="tsTitle">奥尔夫音乐</p>
              <p class="tsText">
                奥尔夫音乐课程专门为小班的孩子设计，旨在培养他们的感知能力、领悟能力、节奏感，以及音乐欣赏能力。通过积极参与音乐活动，孩子们锻炼了身体各部位的协调能力、记忆力、创造力，以及交流沟通能力。孩子们将亲自参与说、唱、奏、听和动的音乐活动，以探索、发现、表现和创造音乐艺术。
              </p>
            </div>
            <div class="tsLeft">
              <img
                src="https://lwjy.oss-cn-beijing.aliyuncs.com/school/aoerfuyinyue.png"
                class="tsImg"
                style="margin-left: 68px"
              />
            </div>
          </div>
          <div class="tsThree">
            <div class="tsLeft">
              <img
                src="https://lwjy.oss-cn-beijing.aliyuncs.com/school/guoxuechuantongwenhua.png"
                class="tsImg"
                style="margin-top: 40px"
              />
            </div>
            <div class="tsRight">
              <p class="tsTitle">国学传统文化</p>
              <p class="tsText">
                引入了国学传统文化课程，旨在培养孩子的道德观念、审美观、人生观和世界观。通过传统文化的学习和熏陶，帮助孩子养成良好的习惯，积累知识储备，构建家国情感。孩子们将在这个课程中培养汉语韵律感、汉语语感，朗朗诵读的习惯，以及古典词律的熏陶。这些将有助于孩子的品德修养和文明行为习惯。
              </p>
            </div>
          </div>
          <div class="tsFoure">
            <div class="tsRight">
              <p class="tsTitle">STEM探索</p>
              <p class="tsText">
                STEM课程的目标是通过探究和实践，帮助孩子更好地理解自然世界，培养创新能力、想象力、操作能力、合作能力，以及解决问题的能力。同时，孩子们将学习数学知识，提高数学素养，培养逻辑思维和抽象思维能力。
              </p>
            </div>
            <div class="tsLeft">
              <img
                src="https://lwjy.oss-cn-beijing.aliyuncs.com/school/stemtansuo.png"
                class="tsImg"
                style="margin-left: 68px"
              />
            </div>
          </div>
          <div class="tsThree">
            <div class="tsLeft">
              <img
                src="https://lwjy.oss-cn-beijing.aliyuncs.com/school/guojixiangqi.png"
                class="tsImg"
              />
            </div>
            <div class="tsRight">
              <p class="tsTitle">国际象棋</p>
              <p class="tsText">
                国际象棋是一项有助于智力发展的游戏。孩子们将学会观察棋盘，做出独立判断，并对自己的棋步负责。这有助于提高他们的思考能力、受挫能力，以及正面面对输赢的态度。此外，国际象棋还培养了孩子的合作能力和社会交往能力，帮助他们建立良好的思维习惯。
              </p>
            </div>
          </div>
          <div class="tsFoure">
            <div class="tsRight">
              <p class="tsTitle">高尔夫</p>
              <p class="tsText">
                高尔夫是一项培养柔韧性、协调性和爆发力的运动，同时有助于强化身体素质。孩子们将培养礼貌和耐心，因为高尔夫有许多规则和礼仪需要遵守。这个课程还有助于培养孩子的自律性，因为孩子需要自我约束并遵守规则。
              </p>
            </div>
            <div class="tsLeft">
              <img
                src="https://lwjy.oss-cn-beijing.aliyuncs.com/school/gaoerfu.png"
                class="tsImg"
                style="margin-left: 68px"
              />
            </div>
          </div>
          <div class="tsThree">
            <div class="tsLeft">
              <img
                src="https://lwjy.oss-cn-beijing.aliyuncs.com/school/xijubiaoyan.png"
                class="tsImg"
              />
            </div>
            <div class="tsRight">
              <p class="tsTitle">戏剧表演</p>
              <p class="tsText">
                戏剧表演课程激发孩子的表现欲和语言能力，提高社会交往能力，增强自信心。孩子们将在老师的引导下进行排练，表演话剧，增强他们的表达能力和自信心。戏剧表演有助于培养孩子的自发性和主动性。
              </p>
            </div>
          </div>
          <div class="tsFoure">
            <div class="tsRight">
              <p class="tsTitle">养成教育</p>
              <p class="tsText">
                幼儿园通过养成教育课程，培养孩子各方面的良好习惯，从一日生活的各个环节开始。这有助于孩子养成良好的行为习惯和生活习惯。
              </p>
            </div>
            <div class="tsLeft">
              <img
                src="https://lwjy.oss-cn-beijing.aliyuncs.com/school/yangchengjiaoyu.png"
                class="tsImg"
                style="margin-left: 68px"
              />
            </div>
          </div>
          <div class="tsThree">
            <div class="tsLeft">
              <img
                src="https://lwjy.oss-cn-beijing.aliyuncs.com/school/zizhuyuedu.png"
                class="tsImg"
              />
            </div>
            <div class="tsRight">
              <p class="tsTitle">自主阅读</p>
              <p class="tsText">
                我们鼓励孩子将自己的图书带到幼儿园，与同伴分享阅读。这个活动有助于孩子提高阅读兴趣，增强阅读的成就感，培养语言表达能力。同时，孩子们将参与戏剧排练，将阅读内容改编成戏剧，增强他们的创造力和表达能力。
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 移动端 -->
    <div class="teseKcPhoneShow">
      <div class="tsTitleBj">
        <div class="tsTitleWz">特色课程</div>
      </div>
      <div class="teseKcPhoneBox">
        <div class="teseKcPhoneTitle">美术创作</div>
        <div class="teseKcPhoneImg">
          <img
            src="https://lwjy.oss-cn-beijing.aliyuncs.com/school/course/meishuchuangzuo.png"
            alt=""
          />
        </div>
        <div class="teseKcPhoneContent">
          在燕京雍阳，我们鼓励孩子们从3岁左右就开始接触美术。美术课程有助于提高孩子的手脑协调能力、创造力和观察力。通过涂鸦、涂色和粘贴等方式，孩子们可以自由表达情感和想法，培养他们的艺术潜能。在4岁左右，孩子们的绘画技能将更加成熟，他们可以开始使用画笔，逐渐培养自己的绘画风格和技巧。在5-7岁的阶段，美术活动将有助于培养孩子的观察力、想象力和创造力，同时提高他们的合作和团队合作能力。
        </div>
      </div>

      <div class="teseKcPhoneBox">
        <div class="teseKcPhoneTitle">奥尔夫音乐</div>
        <div class="teseKcPhoneImg">
          <img
            src="https://lwjy.oss-cn-beijing.aliyuncs.com/school/course/aoerfuyinyue.png"
            alt=""
          />
        </div>
        <div class="teseKcPhoneContent">
          奥尔夫音乐课程专门为小班的孩子设计，旨在培养他们的感知能力、领悟能力、节奏感，以及音乐欣赏能力。通过积极参与音乐活动，孩子们锻炼了身体各部位的协调能力、记忆力、创造力，以及交流沟通能力。孩子们将亲自参与说、唱、奏、听和动的音乐活动，以探索、发现、表现和创造音乐艺术。
        </div>
      </div>

      <div class="teseKcPhoneBox">
        <div class="teseKcPhoneTitle">国学传统文化</div>
        <div class="teseKcPhoneImg">
          <img
            src="https://lwjy.oss-cn-beijing.aliyuncs.com/school/course/guoxuechuantongwenhua.png"
            alt=""
          />
        </div>
        <div class="teseKcPhoneContent">
          我们引入了国学传统文化课程，旨在培养孩子的道德观念、审美观、人生观和世界观。通过传统文化的学习和熏陶，帮助孩子养成良好的习惯，积累知识储备，构建家国情感。孩子们将在这个课程中培养汉语韵律感、汉语语感，朗朗诵读的习惯，以及古典词律的熏陶。这些将有助于孩子的品德修养和文明行为习惯。
        </div>
      </div>

      <div class="teseKcPhoneBox">
        <div class="teseKcPhoneTitle">STEM探索</div>
        <div class="teseKcPhoneImg">
          <img
            src="https://lwjy.oss-cn-beijing.aliyuncs.com/school/course/stemTansuo.png"
            alt=""
          />
        </div>
        <div class="teseKcPhoneContent">
          STEM课程的目标是通过探究和实践，帮助孩子更好地理解自然世界，培养创新能力、想象力、操作能力、合作能力，以及解决问题的能力。同时，孩子们将学习数学知识，提高数学素养，培养逻辑思维和抽象思维能力。
        </div>
      </div>

      <div class="teseKcPhoneBox">
        <div class="teseKcPhoneTitle">国际象棋</div>
        <div class="teseKcPhoneImg">
          <img
            src="https://lwjy.oss-cn-beijing.aliyuncs.com/school/course/guojixiangqi.png"
            alt=""
          />
        </div>
        <div class="teseKcPhoneContent">
          国际象棋是一项有助于智力发展的游戏。孩子们将学会观察棋盘，做出独立判断，并对自己的棋步负责。这有助于提高他们的思考能力、受挫能力，以及正面面对输赢的态度。此外，国际象棋还培养了孩子的合作能力和社会交往能力，帮助他们建立良好的思维习惯。
        </div>
      </div>

      <div class="teseKcPhoneBox">
        <div class="teseKcPhoneTitle">高尔夫</div>
        <div class="teseKcPhoneImg">
          <img
            src="https://lwjy.oss-cn-beijing.aliyuncs.com/school/course/gaoerfu.png"
            alt=""
          />
        </div>
        <div class="teseKcPhoneContent">
          高尔夫是一项培养柔韧性、协调性和爆发力的运动，同时有助于强化身体素质。孩子们将培养礼貌和耐心，因为高尔夫有许多规则和礼仪需要遵守。这个课程还有助于培养孩子的自律性，因为孩子需要自我约束并遵守规则。
        </div>
      </div>

      <div class="teseKcPhoneBox">
        <div class="teseKcPhoneTitle">戏剧表演</div>
        <div class="teseKcPhoneImg">
          <img
            src="https://lwjy.oss-cn-beijing.aliyuncs.com/school/course/xijubiaoyan.png"
            alt=""
          />
        </div>
        <div class="teseKcPhoneContent">
          戏剧表演课程激发孩子的表现欲和语言能力，提高社会交往能力，增强自信心。孩子们将在老师的引导下进行排练，表演话剧，增强他们的表达能力和自信心。戏剧表演有助于培养孩子的自发性和主动性。
        </div>
      </div>

      <div class="teseKcPhoneBox">
        <div class="teseKcPhoneTitle">养成教育</div>
        <div class="teseKcPhoneImg">
          <img
            src="https://lwjy.oss-cn-beijing.aliyuncs.com/school/course/yangchengjiaoyu.png"
            alt=""
          />
        </div>
        <div class="teseKcPhoneContent">
          幼儿园通过养成教育课程，培养孩子各方面的良好习惯，从一日生活的各个环节开始。这有助于孩子养成良好的行为习惯和生活习惯。
        </div>
      </div>

      <div class="teseKcPhoneBox">
        <div class="teseKcPhoneTitle">自主阅读</div>
        <div class="teseKcPhoneImg">
          <img
            src="https://lwjy.oss-cn-beijing.aliyuncs.com/school/course/zizhuyuedu.png"
            alt=""
          />
        </div>
        <div class="teseKcPhoneContent">
          我们鼓励孩子将自己的图书带到幼儿园，与同伴分享阅读。这个活动有助于孩子提高阅读兴趣，增强阅读的成就感，培养语言表达能力。同时，孩子们将参与戏剧排练，将阅读内容改编成戏剧，增强他们的创造力和表达能力。
        </div>
      </div>
    </div>

    <footerBtm class="dibu"></footerBtm>
  </div>
</template>

<script>
import headerNav from "@/components/nav/nav";
import footerBtm from "@/components/footer";
export default {
  name: "xinwen",
  components: { headerNav, footerBtm },
  data() {
    return {
      bannerList: [],
    };
  },
  mounted() {
    this.api.allPage({ name: '课程介绍' }).then((res) => {})
    // console.log(document.getElementById("xinwen"));
    // if (this.$route.query.title == "STEM探索") {
    //   window.scrollTo(0, 5500);
    // }
    
    window.addEventListener("scroll", this.scrollToTop);
    // banner
    this.api.banner({ remark1: 10 }).then((res) => {
      for (var i = 0; i < res.data.length; i++) {
        res.data[i].img = JSON.parse(res.data[i].img);
      }
      this.bannerList = res.data;
    });
  },
  updated(){
    if (this.$route.query.type) {
      if (this.$route.query.type == "two") {
        if (this.$route.query.title == "美术创作") {
          window.scrollTo(0, 3410);
        } else if (this.$route.query.title == "奥尔夫音乐") {
          window.scrollTo(0, 4197);
        } else if (this.$route.query.title == "国学传统文化") {
          window.scrollTo(0, 4750);
        } else if (this.$route.query.title == "STEM探索") {
          window.scrollTo(0, 5337);
        } else if (this.$route.query.title == "国际象棋") {
          window.scrollTo(0, 5935);
        } else if (this.$route.query.title == "高尔夫") {
          window.scrollTo(0, 6476);
        } else if (this.$route.query.title == "戏剧表演") {
          window.scrollTo(0, 7108);
        } else if (this.$route.query.title == "养成教育") {
          window.scrollTo(0, 7717);
        } else if (this.$route.query.title == "自主阅读") {
          window.scrollTo(0, 8326);
        }
      } else if (this.$route.query.type == "one") {
        if (this.$route.query.title == "STEM探索") {
          window.scrollTo(0, 1465);
        } else if (this.$route.query.title == "语言艺术") {
          window.scrollTo(0, 1765);
        } else if (this.$route.query.title == "艺术与创意") {
          window.scrollTo(0, 2103);
        } else if (this.$route.query.title == "活动与体育") {
          window.scrollTo(0, 2419);
        } else if (this.$route.query.title == "自然拼读音") {
          window.scrollTo(0, 2713);
        }
      }
    } else {
      console.log("999");
      window.scrollTo(0, 0);
    }
  },
  methods: {
    scrollToTop() {
      var scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      console.log(scrollTop);
    },
  },
};
</script>
<style scoped>
.el-carousel,
.el-carousel__item,
.el-carousel img {
  width: 100%;
}
.titleBj {
  width: 1200px;
  height: 220px;
  margin: 0 auto;
  background-image: url(https://lwjy.oss-cn-beijing.aliyuncs.com/school/kechengjieshaoTitle.png);
  background-size: 100% 100%;
  margin-top: 30px;
}
.titleWz {
  width: 240px;
  height: 100px;
  font-size: 60px;
  font-weight: 500;
  color: #25ae85;
  line-height: 140px;
  margin: 0 auto;
  padding-top: 12px;
}
.pTitle {
  width: 360px;
  height: auto;
  font-size: 40px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 600;
  color: #252525;
  line-height: 40px;
  margin: 0 auto;
  padding-top: 20px;
}
.pInfo {
  width: 1232px;
  height: 48px;
  font-size: 22px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #252525;
  line-height: 48px;
  margin: 0 auto;
  padding-top: 11px;
}

.jsBig {
  width: 1280px;
  height: 513px;
  background: #25ae85;
  border-radius: 40px 40px 40px 40px;
  opacity: 1;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  margin-top: 75px;
}
.leftBig {
}
.bigText {
  width: 707px;
  height: 192px;
  font-size: 24px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 48px;
  margin-top: 161px;
}
.bigImg {
  width: 393px;
  height: 393px;
  margin-top: 60px;
  margin-left: 60px;
}
.jsH {
  width: 1280px;
  height: 288px;
  background: #ffe500;
  border-radius: 40px 40px 40px 40px;
  opacity: 1;
  margin: 0 auto;
  margin-top: 30px;
}
.jsL {
  width: 1280px;
  height: 288px;
  background: #25ae85;
  border-radius: 40px 40px 40px 40px;
  opacity: 1;
  margin: 0 auto;
  margin-top: 30px;
}
.HTitle {
  width: 235px;
  height: 32px;
  font-size: 32px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #25ae85;
  line-height: 32px;
  margin-left: 60px;
  padding-top: 60px;
}
.HText {
  width: 1160px;
  height: 96px;
  font-size: 24px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #252525;
  line-height: 48px;
  margin-left: 60px;
  padding-top: 40px;
}
.LTitle {
  width: 235px;
  height: 32px;
  font-size: 32px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffe500;
  line-height: 32px;
  margin-left: 60px;
  padding-top: 60px;
}
.LText {
  width: 1160px;
  height: 96px;
  font-size: 24px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 48px;
  margin-left: 60px;
  padding-top: 40px;
}
.tsBj {
  width: 100%;
  height: 6244px;
  background-image: url(https://lwjy.oss-cn-beijing.aliyuncs.com/school/tesekechengBigBJ.png);
  background-size: 100% 100%;
  margin-top: -212px;
}
.tsTitleBj {
  width: 1200px;
  height: 135px;
  margin: 0 auto;
  background-image: url(https://lwjy.oss-cn-beijing.aliyuncs.com/school/tesekechengYeTitle.png);
  background-size: 100% 100%;
  margin-top: 89px;
}
.tsTitleWz {
  width: 320px;
  height: 100px;
  font-size: 80px;
  font-weight: 500;
  color: #25ae85;
  line-height: 140px;
  margin: 0 auto;
  padding-top: 12px;
}
.tsOne {
  width: 100%;
  height: 594px;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  display: flex;
  justify-content: center;
  padding-top: 325px;
}
.tsImg {
  width: 593px;
  height: 474px;
}
.tsTitle {
  width: 400px;
  height: 60px;
  font-size: 60px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #25ae85;
  line-height: 60px;
  margin-left: 68px;
  margin-top: 60px;
}
.tsText {
  width: 619px;
  height: 389px;
  font-size: 24px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #252525;
  line-height: 45px;
  margin-left: 68px;
  margin-top: 25px;
}
.tsTwo {
  width: 100%;
  height: 594px;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  display: flex;
  justify-content: center;
  margin-top: 78px;
}
.tsThree {
  width: 100%;
  height: 594px;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  display: flex;
  justify-content: center;
  margin-top: 0px;
}
.tsFoure {
  width: 100%;
  height: 594px;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  display: flex;
  justify-content: center;
  margin-top: 25px;
}
.teseKcPhoneShow {
  display: none;
}
.teseKcPcShow {
  display: block;
}
.dibu {
  margin-top: -250px;
}
@media (min-width: 1366px) and (max-width: 1440px) {
  .tsTitleBj {
    height: 120px;
  }
  .tsTitleWz {
    font-size: 60px;
  }
  .tsTitle {
    font-size: 50px;
  }
   .el-carousel,
  .el-carousel__item,
  .el-carousel img {
    width: 100%;
    height: 563px;
  }
   div /deep/ .el-carousel__container {
    height: 563px !important;
  }
}

@media (min-width: 1281px) and (max-width: 1366px) {
  .el-carousel,
  .el-carousel__item,
  .el-carousel img {
    width: 100%;
    height: 534px;
  }
   div /deep/ .el-carousel__container {
    height: 534px !important;
  }
  .titleBj{
    width: 910px;
    height: 199px;
    margin-top: 57px;
  }
  .titleWz{
    height: auto;
    font-size: 43px;
    line-height: 70px;
    text-align: center;
  }
  .pTitle{
    font-size: 28px;
    text-align: center;
  }
  .pInfo{
    width: 960px;
    font-size: 16px;
    text-align: center;
  }
  .jsBig{
    width: 910px;
    height: 365px;
  }
  .bigText{
    width: 503px;
    height: 280px;
    margin-top:43px;
    font-size: 17px;
    display: flex;
    align-items: center;
  }
  .rightBig{
    display: flex;
    align-items: center;
  }
  .bigImg{
    width: 280px;
    height: 280px;
    margin-top:0;
    margin-left: 43px;
    display: flex;
    align-items: center;
  }
  .jsH{
    width: 910px;
    height: 205px;
    margin-top:20px;
  }
  .HTitle{
    margin-left: 43px;
    padding-top: 43px;
    font-size: 23px;
  }
  .HText{
    width: 825px;
    margin-left: 43px;
    padding-top: 28px;
    font-size: 17px;
    line-height: 34px;
  }
  .jsL{
    width: 910px;
    height: 205px;
    margin-top:20px;
  }
  .LTitle{
    margin-left: 43px;
    padding-top: 43px;
    font-size: 23px;
  }
  .LText{
    width: 825px;
    margin-left: 43px;
    padding-top: 28px;
    font-size: 17px;
    line-height: 34px;
  }

  .tsTitleBj{
    width: 910px;
    height: 100px;
    margin-top: 78px;
  }
  .tsTitleWz{
    width: 228px;
    height: auto;
    line-height: 100px;
    font-size: 57px;
  }
  .tsBj{
    height: 4442px;
    margin-top: -144px;
  }
  .tsOne{
    height: 337px;
  }
  .tsImg{
    margin-top: 0 !important;
    width: 422px;
    height: 337px;
  }
  .tsTitle{
    margin-top: 0;
    margin-left: 48px;
    font-size: 43px;
  }
  .tsText{
    height: auto;
    width: 440px;
    margin-left: 48px;
    margin-top: 18px;
    font-size: 17px;
    line-height: 34px;
  }
  .tsTwo{
    height: 337px;
    align-items: center;
  }
  .tsThree,.tsFoure{
    margin-top: 85px;
    height: 337px;
    align-items: center;
  }
}

@media (min-width: 1080px) and (max-width: 1280px) {
  .el-carousel,
  .el-carousel__item,
  .el-carousel img {
    width: 100%;
    height: 500px;
  }
   div /deep/ .el-carousel__container {
    height: 500px !important;
  }
  .titleBj{
    width: 853px;
    height: 187px;
    margin-top: 53px;
  }
  .titleWz{
    height: auto;
    font-size: 40px;
    line-height: 80px;
    text-align: center;
  }
  .pTitle{
    font-size: 27px;
    text-align: center;
    padding-top: 15px;
  }
  .pInfo{
    width: 840px;
    font-size: 14px;
    text-align: center;
    line-height: 32px;
  }
  .jsBig{
    width: 853px;
    height: 342px;
    margin-top: 50px;
    border-radius: 27px;
  }
  .bigText{
    width: 471px;
    height: 262px;
    margin-top:43px;
    font-size: 16px;
    display: flex;
    align-items: center;
    line-height: 32px;
  }
  .rightBig{
    display: flex;
    align-items: center;
  }
  .bigImg{
    width: 262px;
    height: 262px;
    margin-top:0;
    margin-left: 03px;
    display: flex;
    align-items: center;
  }
  .jsH{
    width: 853px;
    height: 192px;
    margin-top:20px;
  }
  .HTitle{
    margin-left: 40px;
    padding-top: 40px;
    font-size: 21px;
  }
  .HText{
    width: 773px;
    margin-left: 40px;
    padding-top: 28px;
    font-size: 16px;
    line-height: 32px;
  }
  .jsL{
    width: 853px;
    height: 192px;
    margin-top:20px;
  }
  .LTitle{
    margin-left: 40px;
    padding-top: 40px;
    font-size: 21px;
  }
  .LText{
    width: 773px;
    margin-left: 40px;
    padding-top: 28px;
    font-size: 16px;
    line-height: 32px;
  }

  .tsTitleBj{
    width: 852px;
    height: 93px;
    margin-top: 73px;
  }
  .tsTitleWz{
    width: 213px;
    height: auto;
    line-height: 93px;
    font-size: 53px;
  }
  .tsBj{
    height: 4163px;
    margin-top: -134px;
  }
  .tsOne{
    height: 316px;
    padding-top: 282px;
  }
  .tsImg{
    margin-top: 0 !important;
    width: 395px;
    height: 316px;
  }
  .tsTitle{
    margin-top: 0;
    margin-left: 45px;
    font-size: 40px;
  }
  .tsText{
    height: auto;
    width: 413px;
    margin-left: 45px;
    margin-top: 18px;
    font-size: 16px;
    line-height: 32px;
  }
  .tsTwo{
    height: 316px;
    align-items: center;
  }
  .tsThree,.tsFoure{
    margin-top: 80px;
    height: 316px;
    align-items: center;
  }
}

@media screen and (max-width: 750px) {
  div /deep/ .el-carousel__container {
    height: 146px !important;
  }
  div /deep/ .el-carousel__container img {
    height: 100% !important;
  }
  .el-carousel,
  .el-carousel__item,
  .el-carousel img {
    width: 100%;
    height: 146px;
  }
  .bigBox {
    padding: 0 13px;
    box-sizing: border-box;
  }
  .titleBj {
    width: 100%;
    height: 81px;
  }
  .titleWz {
    height: 24px;
    font-size: 30px;
    line-height: 24px;
    text-align: center;
    font-weight: bold;
  }
  .pTitle {
    width: 100%;
    font-size: 16px;
    line-height: 16px;
    text-align: center;
  }
  .pInfo {
    width: 100%;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
  }

  .jsBig {
    width: 100%;
    height: 204px;
    padding: 20px 16px;
    box-sizing: border-box;
    justify-content: space-around;
    align-items: center;
  }
  .bigText {
    width: 160px;
    height: 100%;
    font-size: 12px;
    line-height: 18px;
    margin-top: 0;
    box-sizing: border-box;
  }
  .bigImg {
    width: 131px;
    height: 131px;
    margin: 0;
  }

  .jsH {
    width: 100%;
    height: 141px;
    border-radius: 10px;
  }
  .HTitle {
    height: auto;
    margin-left: 16px;
    padding-top: 20px;
    font-size: 16px;
    line-height: 16px;
  }
  .HText {
    width: 100%;
    height: 72px;
    padding: 13px 16px 0 16px;
    margin-left: 0;
    box-sizing: border-box;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 2px;
  }

  .jsL {
    width: 100%;
    height: 141px;
    border-radius: 10px;
  }
  .LTitle {
    height: auto;
    margin-left: 16px;
    padding-top: 20px;
    font-size: 16px;
    line-height: 16px;
  }
  .LText {
    width: 100%;
    height: 72px;
    padding: 13px 16px 0 16px;
    margin-left: 0;
    box-sizing: border-box;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 2px;
  }

  .tsTitleBj {
    width: 100%;
    height: 47px;
    margin-top: 27px;
  }
  .tsTitleWz {
    width: 100%;
    height: 47px;
    font-size: 30px;
    line-height: 20px;
    margin-top: 16px;
    padding-top: 20px;
    box-sizing: border-box;
    text-align: center;
    font-weight: bold;
  }
  .teseKcPcShow {
    display: none;
  }
  .teseKcPhoneShow {
    display: block;
    margin-top: 29px;
    width: 100%;
    padding: 0 16px;
    box-sizing: border-box;
    height: 2840px;
    background-image: url(https://lwjy.oss-cn-beijing.aliyuncs.com/school/tesekechengBigBJ.png);
    background-size: 100% 100%;
    background-position: 0 -30px;
    background-repeat: no-repeat;
  }

  .teseKcPhoneBox {
    width: 100%;
    margin-bottom: 27px;
    margin-top: 27px;
  }
  .teseKcPhoneTitle {
    font-size: 24px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 600;
    color: #25ae85;
    text-align: center;
  }
  .teseKcPhoneImg {
    width: 100%;
    height: 133px;
    margin: 12px 0;
  }
  .teseKcPhoneImg img {
    width: 100%;
    height: 100%;
  }
  .teseKcPhoneContent {
    font-size: 12px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #252525;
    line-height: 18px;
    letter-spacing: 2px;
  }
  .dibu {
    margin-top: 0px;
  }
}
</style>
